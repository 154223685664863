
import { Vue, Component } from 'vue-property-decorator'
import layout from '@/views/FrameBase/Main.vue'
import HelpFooter from './components/HelpFooter.vue'
import { helps } from './helps'
import { siteConfig } from '@/config/site'
import { lang } from '@/lang'

@Component({
  components: {
    layout,
    HelpFooter
  }
})

export default class FrameBase extends Vue {
  private get detail () {
    const categoryIndex = this.$route.query.category || 0
    const questionIndex = this.$route.query.index || 0

    return {
      category: this.helps[categoryIndex as number].category,
      detail: this.helps[categoryIndex as number].list[questionIndex as number]
    }
  }

  private get helps () {
    return helps[siteConfig.lang as 'CN' | 'EN']
  }

  private goBack () {
    window.history.back()
  }
}
